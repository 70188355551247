import {instructorsActionTypes} from "../action-types/instructors-action.types";

const initState = Object.freeze({
    instructorsList: [],
    totalCount: 0,
    isLoading: false
})

export const instructorsReducer = (state = initState, {type, payload}) => {
    switch (type) {
        case instructorsActionTypes.SET_IS_LOADING:
            return {...state, isLoading: payload}
        case instructorsActionTypes.CLEAR_LIST:
            return {...initState}
        case instructorsActionTypes.SET_LIST:
            return {...state, totalCount: payload.totalCount, instructorsList: payload.instructorsList}
        default: return state
    }
}
