import styles from './content-wrapper.module.scss'

export const ContentWrapper = ({children}) => {
    return (
        <main className={styles.wrapper}>
           <div className={styles.wrapper__scrolled}>
               {children}
           </div>
        </main>

    )
}