import {useNavigate} from "react-router-dom";

export const WorkoutsListTableRow = ({_id, title, bodyPart, style, instructor}) => {
    const navigate = useNavigate()

    return (
        <tr onClick={navigate.bind(null, `/dashboard/workouts/${_id}`)}>
            <td>{title}</td>
            <td>{bodyPart}</td>
            <td>{style}</td>
            <td>{instructor}</td>
        </tr>
    )
}