import {applyMiddleware, compose, createStore} from "redux";
import thunk from "redux-thunk";
import {reducers} from "./combined-reducers";

const composeSetup = process.env.NODE_ENV !== 'production' && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose

const middlewareEnhancer = applyMiddleware(thunk);

export const store = createStore(reducers, {}, composeSetup(middlewareEnhancer));
