import {BaseAPI} from "./BaseAPI";

class WorkoutsAPI extends BaseAPI{
    constructor() {
        super('workout')
    }

    createWorkout = async (workoutData) => {
        return await this.service.post('', workoutData)
    }

    updateWorkout = async (workoutData, id) => {
        return await this.service.put(`/${id}`, workoutData)
    }

    getById = async id => {
        return await this.service.get(`/${id}`)
    }

    updateWorkoutVideo = async (workoutVideoData, id) => {
        return await this.service.put(`/${id}/video`, workoutVideoData)
    }

    deleteWorkout = async (id) => {
        return await this.service.delete(`/${id}`)
    }

    getAllWorkouts = async ({bodyPart, difficult, offset = 0, limit = 20, text}) => {
        return await this.service.get('/all', {
            params: {
                bodyPart,
                difficult,
                offset,
                limit,
                text
            }
        })
    }

}

export default WorkoutsAPI
