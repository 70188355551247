import styles from "./users-list.module.scss";
import {UsersListTableRow} from "./UsersListTableRow";

export const UsersListTable = ({usersList}) => {

    return usersList?.length ? (
        <table className={styles.users__table}>
            <thead>
            <tr>
                <td>Name</td>
                <td>Email</td>
                <td>Role</td>
                <td>Status</td>
            </tr>
            </thead>
            <tbody>
            {usersList?.map(UsersListTableRow)}
            </tbody>
        </table>
    ) : <h2 id={'nothing-found'}>Nothing found....</h2>
}
