import {getLocalAccessToken} from "../../services/token.service";

export const onRequestFulfilled = (config) => {
    const token = getLocalAccessToken();
    if (token) config.headers["Authorization"] = 'Bearer ' + token;
    return config;
}

export const onRequestReject = (error) => {
    return Promise.reject(error);
}
