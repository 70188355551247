import {Link, useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import ChallengesAPI from "../../../api/ChallengesAPI";
import {addAlertContent} from "../../../store/actions/alerts-actions";
import {useDispatch} from "react-redux";

import styles from './challenge-page.module.scss'
import {Button} from "../../../components/button/Button";
import {useAlert} from "../../../hooks/useAlert";

export const ChallengePage = (props) => {
    const {id} = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [challengeData, setChallengeData] = useState(null)

    const [successAlert, errorAlert] = useAlert()

    const removeHandler = () => {
        new ChallengesAPI().deleteChallenge(id).then(res => {
            successAlert(res.data.message)
            navigate('/dashboard/challenges')
        }).catch(errorAlert)
    }

    useEffect(() => {
        new ChallengesAPI().getChallengeById(id).then(({data}) => setChallengeData(data)).catch(error => {
            errorAlert(error)
            navigate('/dashboard/challenges', {replace: true})
        })
    }, [])

    return (
        <>
            <section className={styles.challenge__basic}>
                <img className={styles.challenge__basic_thumbnail} src={challengeData?.thumbnail} alt={'thumbnail'}/>
                <div className={styles.challenge__basic__texts}>
                    <p className={styles.challenge__basic__texts__paragraph}><span>Title:</span> {challengeData?.title}
                    </p>
                    <p className={styles.challenge__basic__texts__paragraph}>
                        <span>Description:</span> {challengeData?.description}</p>
                    <p className={styles.challenge__basic__texts__paragraph}>
                        <span>Difficulty:</span> {challengeData?.difficulty}</p>
                    <p className={styles.challenge__basic__texts__paragraph}>
                        <span>Is Primary:</span> {`${challengeData?.isPrimary}`}</p>
                </div>
            </section>
            <section className={styles.challenge__workouts}>
                <p className={styles.challenge__workouts_header}>Workouts</p>

                <div className={styles.challenge__workouts__list}>
                    {challengeData?.workouts?.map((w, index) => {
                        return w ? (
                            <Link target="_blank" rel="noopener noreferrer"
                                  to={`/dashboard/workouts/${w?._id}`} key={w?._id}
                                  className={styles.challenge__workouts__list__tile}>
                                <p>Day {index + 1 + challengeData?.offsetDays}</p>
                                <img src={w?.video?.thumbnail} alt={w?.title}/>
                                <p className={styles.challenge__workouts__list__tile_description}>{w?.title}</p>
                            </Link>
                        ) : (
                            <div key={w?._id} className={styles.challenge__workouts__list__tile}>
                                <p>Day {index + 1 + challengeData?.offsetDays}</p>
                                <span>Day off</span>
                            </div>
                        )
                    })}
                </div>
            </section>
            <section className={styles.challenge__workouts}>
                <p className={styles.challenge__workouts_header}>Alternative Workouts</p>

                <div className={styles.challenge__workouts__list}>
                    {challengeData?.altWorkouts?.map((w, index) => {
                        return w ? (
                            <Link target="_blank" rel="noopener noreferrer"
                                  to={`/dashboard/workouts/${w?._id}`} key={w?._id}
                                  className={styles.challenge__workouts__list__tile}>
                                <p>Day {index + 1 + challengeData?.offsetDays}</p>
                                <img src={w?.video?.thumbnail} alt={w?.title}/>
                                <p className={styles.challenge__workouts__list__tile_description}>{w?.title}</p>
                            </Link>
                        ) : (
                            <div key={w?._id} className={styles.challenge__workouts__list__tile}>
                                <p>Day {index + 1 + challengeData?.offsetDays}</p>
                                <span>Day off</span>
                            </div>
                        )
                    })}
                </div>
            </section>
            <Button className={styles.challenge_remove_button} onClick={removeHandler} title={'Delete challenge'}/>
        </>
    )
}
