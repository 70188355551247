import {EntitiesPageHeader} from "../../../components/search-bar/EntitiesPageHeader";
import {useDispatch, useSelector} from "react-redux";
import {equipmentsSelector} from "../../../store/selectors/equipments-selector";
import {useEffect, useState} from "react";
import {getAllEquipmentsList} from "../../../store/operations/equipments-operations";
import {ModalWindow} from "../../../components/modal-window/ModalWindow";
import {EditWindow} from "../edit-window/EditWindow";
import {DeleteWindow} from "../delete-window/DeleteWindow";
import {CreateWindow} from "../create-window/CreateWindow";
import {EquipmentsListTable} from "./EquipmentsListTable";
import {withAllowedQuery} from "../../../hocs/withAllowedQuery";
import {PaginationPanel} from "../../../components/pagination-panel/PaginationPanel";

export const EquipmentsListPage = withAllowedQuery(['text'])(({queryParams, setQueryParams}) => {
    const [searchText, setSearchText] = useState(queryParams.text || '')
    const [isWindowOpen, setIsWindowOpen] = useState(false)
    const [modalContent, setModalContent] = useState(null)
    const {equipmentsList, totalCount, isLoading} = useSelector(equipmentsSelector)
    const dispatch = useDispatch()

    const editButtonHandler = (entity) => {
        setModalContent(<EditWindow closeModal={setIsWindowOpen.bind(null, false)} selectedEntity={entity}/>)
        setIsWindowOpen(true)
    }

    const deleteButtonHandler = (entity) => {
        setModalContent(<DeleteWindow closeModal={setIsWindowOpen.bind(null, false)} selectedEntity={entity}/>)
        setIsWindowOpen(true)
    }

    const createButtonHandler = () => {
        setModalContent(<CreateWindow closeModal={setIsWindowOpen.bind(null, false)}/>)
        setIsWindowOpen(true)
    }

    useEffect(() => {
        dispatch(getAllEquipmentsList())
    }, [])

    const onPageChange = page => {
        dispatch(getAllEquipmentsList(page * 20, 20, searchText))
    }

    useEffect(() => {
        dispatch(getAllEquipmentsList(0, 20, searchText))
    }, [queryParams])

    const searchButtonHandler = (event) => {
        event.preventDefault()
        setQueryParams({
            text: searchText
        })
    }

    return (
        <>
            <ModalWindow isWindowOpen={isWindowOpen} setIsWindowOpen={setIsWindowOpen}>
                {modalContent}
            </ModalWindow>
            <EntitiesPageHeader input={{
                value: searchText,
                onChange: setSearchText,
                disabled: false
            }} buttons={[
                {title: 'Search', type: 'submit', clickHandler: searchButtonHandler},
                {title: 'Create', clickHandler: createButtonHandler}
            ]} placeholder={'Input equipment name here'}/>
            <section>
                <EquipmentsListTable equipmentsList={equipmentsList} e={editButtonHandler} d={deleteButtonHandler}/>
                <PaginationPanel onPageChange={onPageChange} initPage={0} totalPages={Math.ceil(totalCount / 20)}/>
            </section>
        </>
    )
})
