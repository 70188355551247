import {BaseAPI} from "./BaseAPI";

export class InstructorsAPI extends BaseAPI {
    constructor() {
        super('coach');
    }

    getInstructorsList = async (offset = 0, limit = 20) => {
        return await this.service.get('/', {
            params: {
                offset,
                limit
            }
        })
    }

    createNewInstructor = async name => {
        return await this.service.post('/', {name})
    }

    updateInstructor = async (id, name) => {
        return await this.service.put(`/${id}`, {name})
    }

    deleteInstructor = async id => {
        return await this.service.delete(`/${id}`)
    }
}
