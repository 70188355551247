import {useDispatch, useSelector} from "react-redux";
import {profileSelector} from "../store/selectors/profile-selector";
import {Navigate} from "react-router-dom";
import {useEffect} from "react";
import {requestUserData} from "../store/operations/profile-operations";
import {getLocalAccessToken} from "../services/token.service";
import {addAlertContent} from "../store/actions/alerts-actions";

export const withAuth = (Component) => {
    return props => {
        const {profileData, isLoading} = useSelector(profileSelector)
        const dispatch = useDispatch()

        const displayErrorMessage = (error) => {
            dispatch(addAlertContent({
                Content: () => <p>{error.message}</p>,
                isError: true
            }))
        }

        const displaySuccessMessage = (message) => {
            dispatch(addAlertContent({
                Content: () => <p>{message}</p>,
                isError: false
            }))
        }

        useEffect(() => {
           if (!profileData && getLocalAccessToken()) {
                dispatch(requestUserData(() => {}, displayErrorMessage))
           }
        }, [profileData])

        if (profileData) return <Component {...props}/>

        if (isLoading || getLocalAccessToken()) return <p>Loading...</p>

        return <Navigate to={"/auth"}/>
    }
}