import {
    CLEAR_CHALLENGES_LIST,
    SAVE_CHALLENGES_LIST,
    SET_CHALLENGES_LOADING_STATUS
} from "../action-types/challenges-action.types";

const initState = {
    challengesList: [],
    totalCount: 0,
    isLoading: false
}

export const challengesReducer = (state = initState, {type, payload}) => {
    switch (type) {
        case SAVE_CHALLENGES_LIST:
            return {...state, challengesList: payload.challengesList, totalCount: payload.totalCount}
        case CLEAR_CHALLENGES_LIST:
            return {...state, challengesList: [], totalCount: 0}
        case SET_CHALLENGES_LOADING_STATUS:
            return {...state, isLoading: payload}
        default: return state
    }
}