import {ADD_USERS_TO_LIST, CLEAR_USERS_LIST, SET_USERS_LOADING_STATUS} from "../action-types/users-action.types";

const initState = {
    usersList: [],
    totalCount: 0,
    isLoading: false
}

export const usersReducer = (state = initState, {type, payload}) => {
    switch (type) {
        case ADD_USERS_TO_LIST:
            return {...state, usersList: payload.usersList, totalCount: payload.totalCount}
        case CLEAR_USERS_LIST:
            return {...state, usersList: [], totalCount: 0}
        case SET_USERS_LOADING_STATUS:
            return {...state, isLoading: payload}
        default: return state
    }
}