import {
    CLEAR_EQUIPMENTS_LIST,
    SAVE_EQUIPMENTS_LIST,
    SET_EQUIPMENTS_IS_LOADING_STATUS
} from "../action-types/equipments-action.types";

export const saveEquipmentsList = (equipmentsList, totalCount) => {
    return {type: SAVE_EQUIPMENTS_LIST, payload: {equipmentsList, totalCount}}
}

export const clearEquipmentsList = () => {
    return {type: CLEAR_EQUIPMENTS_LIST}
}

export const setIsEquipmentsLoadingStatus = (isLoading) => {
    return {type: SET_EQUIPMENTS_IS_LOADING_STATUS, payload: isLoading}
}