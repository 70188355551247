export const apiBaseUrls = {
    development: 'https://backend-pre-dev.lvlmethod.lampawork.com/api/v1/',
    production: 'https://backend.levlmethod.com/api/v1/',
}

export const bodyParts = [
    {title: "Full Body"},
    {title: "Lower Body"},
    {title: "Upper Body"},
    {title: "ABS"}
]

export const workoutStyles = [
    {title: 'Run'},
    {title: 'Strength'},
    {title: 'HIIT'},
    {title: 'Barre'},
    {title: 'Cycling'},
    {title: 'Yoga'},
    {title: 'Pilates'}
]
