import {BaseAPI} from "./BaseAPI";

class EquipmentsAPI extends BaseAPI{
    constructor() {
        super('equipment')
    }

    getAllEquipments = async (offset, limit, text) => {
        return await this.service.get('/', {
            params: {
                offset,
                limit,
                text
            }
        },)
    }

    getEquipmentsByName = async (name) => {
        return await this.service.get(`/${name}`)
    }

    updateEquipment = async (name, newName) => {
        return await this.service.put(`/${name}`, {name: newName})
    }

    deleteEquipment = async (name) => {
        return await this.service.delete(`/${name}`)
    }

    createEquipment = async (name) => {
        return await this.service.post('/', {name})
    }
}

export default EquipmentsAPI
