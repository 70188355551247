import s from './workout-creation-page.module.scss'
import {useEffect, useRef, useState} from "react";
import {Input} from "../../../components/input/Input";
import {Multiselect, Select} from "../../../components/select-menu";
import sportEquipmentIcon from "../../../assets/image/sport-equipment-icon.png";
import coachIcon from "../../../assets/image/coach.png";
import bodyPartIcon from "../../../assets/image/body-part.png";
import workoutStyleIcon from "../../../assets/image/workout-style-icon.png";
import {bodyParts, workoutStyles} from "../../../assets/constants";
import {useDispatch, useSelector} from "react-redux";
import {instructorsSelector} from "../../../store/selectors/instructorsSelector";
import {getInstructorsList} from "../../../store/operations/instructors-operations";
import {getAllEquipmentsList} from "../../../store/operations/equipments-operations";
import {equipmentsSelector} from "../../../store/selectors/equipments-selector";
import {Button} from "../../../components/button/Button";
import WorkoutsAPI from "../../../api/WorkoutsAPI";
import {useAlert} from "../../../hooks/useAlert";
import {Vimeo} from "vimeo";
import {__ci__, __cs__, __t__} from "../../../config";
import videoFileIcon from "../../../assets/image/video-file.png";
import {useNavigate} from "react-router-dom";

const _baseWorkoutData = {
    title: "",
    difficulty: 1,
    bodyPart: "",
    style: "",
    instructor: "",
    calory: 200,
    hr: 60,
    equipments: []
}

const _basePartData = {
    name: 'Part name',
    nodes: [{
        nodeId: Math.ceil(Math.random() * Date.now()),
        timeStart: '00:00',
        timeEnd: '00:00',
        difficulty: 'LOW',
    }, {
        nodeId: Math.ceil(Math.random() * Date.now()),
        timeStart: '00:00',
        timeEnd: '00:00',
        difficulty: 'MEDIUM',
    }, {
        nodeId: Math.ceil(Math.random() * Date.now()),
        timeStart: '00:00',
        timeEnd: '00:00',
        difficulty: 'HIGH',
    }],
    targetHR: 90,
}

const _basePartsArrayData = [{..._basePartData, partId: Date.now(),}]

export const CreateWorkoutPage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [successAlert, errorAlert] = useAlert()
    const [video, setVideo] = useState(null)
    const [videoPreview, setVideoPreview] = useState(null)
    const [baseWorkoutData, setBaseWorkoutData] = useState(_baseWorkoutData)
    const [isCreatingInProgress, setIsCreatingInProgress] = useState(false)
    const [currentStep, setCurrentStep] = useState(1)
    const [progressPercentage, setProgressPercentage] = useState('0')
    const [innerWorkoutId, setInnerWorkoutId] = useState(null)
    const [vimeoVideoUri, setVimeoVideoUri] = useState(null)
    const [parts, setParts] = useState(_basePartsArrayData)
    const {instructorsList} = useSelector(instructorsSelector)
    const {equipmentsList} = useSelector(equipmentsSelector)

    useEffect(() => {
        dispatch(getInstructorsList(0, 99999))
    }, [])

    useEffect(() => {
        dispatch(getAllEquipmentsList())
    }, [])

    const createVideoData = (file) => {
        return new Promise((resolve) => {
            const _video = document.createElement("video");
            const _canvas = document.createElement('canvas')

            // this is important
            _video.autoplay = true;
            _video.muted = true;
            _video.src = URL.createObjectURL(file);

            _video.onloadeddata = () => {
                let ctx = _canvas.getContext("2d");

                _canvas.width = _video.videoWidth;
                _canvas.height = _video.videoHeight;

                ctx.drawImage(_video, 0, 0);

                _video.pause();

                return resolve({
                    preview: _canvas.toDataURL("image/png"),
                    video: file
                });
            };
        });
    }

    const videoInputHandler = (e) => {
        createVideoData(e.target.files[0]).then(({video, preview}) => {
            setVideo(video)
            setVideoPreview(preview)
        }).catch(err => console.log(err))
    }

    const inputHandler = (e) => {
        settingsHandler(e.target.name, e.target.value)
    }

    const settingsHandler = (name, value) => {
        let _value = value

        if (name === 'style') _value = value?.toUpperCase()

        if (name === 'bodyPart') _value = value?.split(' ').join('_').toUpperCase()

        setBaseWorkoutData({
            ...baseWorkoutData,
            [name]: _value
        })
    }

    const partsChangeHandler = (type, value, partId, nodeId) => {
        let partIndex = parts.findIndex(part => +part.partId === partId);
        let nodeIndex = parts[partIndex].nodes.findIndex(node => +node.nodeId === +nodeId)

        let _newPartsArray = [...parts];

        let _newNodesArray = [...parts[partIndex].nodes]

        switch (type) {
            case 'timeStart':
            case 'timeEnd':
                let timeRegExp = new RegExp(/^([0-9]{0,3})?:([0-9]{0,2})?$/)
                if (value.match(timeRegExp)) {
                    _newNodesArray.splice(nodeIndex, 1, {
                        ...parts[partIndex].nodes[nodeIndex],
                        [type]: value === ':' ? '000:00' : value
                    })
                }
                break;
            default:
                throw new Error('Abd input type')

        }

        _newPartsArray.splice(partIndex, 1, {
            ...parts[partIndex],
            nodes: _newNodesArray
        })

        setParts(_newPartsArray)
    }

    const targetHRHandler = ({target: {value, id}}) => {
        const partIndex = parts.findIndex(part => +part.partId === +id)

        let _newPartsArray = [...parts]

        let hrRegExp = new RegExp(/^([0-9]{0,3})?$/)

        if (value.match(hrRegExp)) {
            _newPartsArray.splice(partIndex, 1, {
                ...parts[partIndex],
                targetHR: value === '' ? '0' : value
            })
        }

        setParts(_newPartsArray)
    }

    const partNameHandler = ({target: {value, id}}) => {
        const partIndex = parts.findIndex(part => +part.partId === +id)

        let _newPartsArray = [...parts]

        _newPartsArray.splice(partIndex, 1, {
            ...parts[partIndex],
            name: value
        })

        setParts(_newPartsArray)
    }

    const addPart = () => {
        setParts([...parts, {..._basePartData, partId: Date.now()}])
    }

    const removePart = (id) => {
        setParts(parts.filter(p => +p.partId !== +id))
    }

    const timeToSeconds = (time) => {
        const [minutes, seconds] = time.split(':')
        return (+minutes * 60) + (+seconds)
    }

    const getPreparedPartsData = () => {
        return parts.map(({name, nodes, targetHR}) => {
            return {
                name,
                nodes: nodes.map(({timeStart, timeEnd, difficulty}) => {
                    return {
                        timeEnd: timeToSeconds(timeEnd),
                        timeStart: timeToSeconds(timeStart),
                        difficult: difficulty
                    }
                }),
                targetHR: +targetHR,
            }
        })
    }

    const createWorkoutHandler = (event) => {
        event.preventDefault()

        const isPartsValid = checkIsPartsValid()

        if (isPartsValid) {
            setIsCreatingInProgress(true)

            new WorkoutsAPI().createWorkout(baseWorkoutData).then(res => {
                successAlert('Workout has been created! Please, upload video!')

                setInnerWorkoutId(res.data._id)

                const onProgressUpdate = (bytes_uploaded, bytes_total) => {
                    setProgressPercentage((bytes_uploaded / bytes_total * 100).toFixed(2))
                }

                const onUploadFinish = uri => {
                    setCurrentStep(3)

                    const [videoId] = uri.match(/[0-9]+/)

                    setVimeoVideoUri(videoId)

                    setCurrentStep(4)
                }

                const onError = error => {
                    errorAlert(error)
                }

                const videoParams = {
                    name: baseWorkoutData.title,
                    description: '',
                    embed: {
                        color: "#FE6D02",
                        buttons: {
                            like: false,
                            embed: false,
                            share: false,
                            watchlater: false,
                        },
                        logos: {
                            vimeo: false,
                        },
                        title: {
                            owner: 'hide',
                            name: 'hide',
                            portrait: 'hide',
                        }
                    },
                    privacy: {
                        add: false,
                        comments: 'nobody',
                        download: false,
                        /*view: 'disable',*/
                    }
                }

                const videoFile = new File([video], "baseWorkoutData.title")

                const vimeoClient = new Vimeo(__ci__, __cs__, __t__)

                setCurrentStep(2)

                vimeoClient.upload(videoFile, videoParams, onUploadFinish, onProgressUpdate, onError)

            }).catch((error) => {
                errorAlert(error)
                setIsCreatingInProgress(false)
                setCurrentStep(1)
            })
        }
    }

    const ProgressContent = () => {

        const onError = (e) => {
            errorAlert(e)
            setIsCreatingInProgress(false)
        }

        const onSuccess = () => {
            successAlert('Workout has been updated!')
            window.open(`${window.location.origin}/dashboard/workouts/${innerWorkoutId}`, '_blank');
            navigate(`/dashboard/workouts`, {target: '_blank'})
        }

        switch (currentStep) {
            case 1:
                return <CreatingWorkout/>
            case 2:
                return <UploadingWorkout progress={progressPercentage}/>
            case 3:
                return <PreparingParts/>
            case 4:
                return <UploadingParts onError={onError} onSuccess={onSuccess} preparedParts={getPreparedPartsData()}
                                       innerWorkoutId={innerWorkoutId} videoId={vimeoVideoUri}/>
            default:
                return null
        }
    }

    const checkIsPartsValid = () => {
        let isError = false

        const invalidParts = parts.filter(({nodes}) => nodes.filter(({timeStart, timeEnd}) => {
            return timeToSeconds(timeStart) <= 0 || (timeToSeconds(timeEnd) - timeToSeconds(timeStart) <= 0)
        }).length)

        let invalidNodes = []

        invalidParts.forEach(({nodes}) => invalidNodes.push(...nodes))

        invalidNodes = invalidNodes.filter(({timeStart}) => timeToSeconds(timeStart) <= 0)

        if (invalidNodes.length > 1) {
            isError = true;
            errorAlert({message: "Only one node can start at 00:00"})
        }

        invalidParts.forEach(({nodes}) => invalidNodes.push(...nodes))

        invalidNodes = invalidNodes.filter(({
                                                timeStart,
                                                timeEnd
                                            }) => (timeToSeconds(timeEnd) - timeToSeconds(timeStart) <= 0))

        if (invalidNodes.length > 1) {
            isError = true;
            errorAlert({message: "Time end should be bigger then time start"})
        }

        return !isError
    }

    return (
        <>

            {isCreatingInProgress && (
                <dialog className={s.dialog} open={true}>
                    <div className={s.dialog__content}>
                        <ProgressContent/>
                        <small>Don't close this window until workout will be created!</small>
                    </div>
                </dialog>
            )}
            <form onSubmit={createWorkoutHandler} className={s.wrapper}>
                <div className={s.wrapper__video}>
                    <label className={s.wrapper__video__input}>
                        <input name={'video_input'} multiple={false} onChange={videoInputHandler} type="file"
                               accept="video/*"/>
                        {!video && <img src={videoFileIcon} alt={'addIcon'}/>}
                        {videoPreview &&
                            <img src={videoPreview} alt={'preview'} className={s.wrapper__video__input__preview}/>}
                    </label>
                    <div className={s.wrapper__video__settings}>
                        <Input required={true} onChange={inputHandler} className={s.wrapper__video__settings__title}
                               name={'title'}
                               placeholder={'Workout Title'}/>
                        <Multiselect placeholder={'Click to select equipment'}
                                     onSelect={settingsHandler}
                                     name={'equipments'}
                                     required={true}
                                     icon={sportEquipmentIcon}
                                     selectClassName={s.wrapper__video__settings__equipment}
                                     options={equipmentsList.map(e => ({title: e.name, _id: e._id}))}/>
                        <Select placeholder={'Click to select instructor'}
                                icon={coachIcon}
                                required={true}
                                onSelect={settingsHandler}
                                name={'instructor'}
                                selectClassName={s.wrapper__video__settings__coach}
                                options={instructorsList.map((i) => ({title: i.name, _id: i._id}))}/>
                        <Select placeholder={'Click to select body part'}
                                icon={bodyPartIcon}
                                required={true}
                                onSelect={settingsHandler}
                                name={'bodyPart'}
                                selectClassName={s.wrapper__video__settings__body_part}
                                options={bodyParts.map(({title}, index) => ({title, _id: index}))}/>
                        <Select placeholder={'Click to select workout style'}
                                icon={workoutStyleIcon}
                                required={true}
                                onSelect={settingsHandler}
                                name={'style'}
                                selectClassName={s.wrapper__video__settings__workout_style}
                                options={workoutStyles.map(({title}, index) => ({title, _id: index}))}/>
                        <fieldset className={s.wrapper__video__settings__difficulty}>
                            <label htmlFor={'difficulty'}>Difficulty ({baseWorkoutData.difficulty})</label>
                            <Input required={true} value={baseWorkoutData.difficulty} onChange={inputHandler}
                                   name={'difficulty'}
                                   type={'range'} min={1} max={10} step={1}/>
                        </fieldset>
                        <fieldset className={s.wrapper__video__settings__calories}>
                            <label htmlFor={'calory'}>Calories ({baseWorkoutData.calory})</label>
                            <Input required={true} value={baseWorkoutData.calory} onChange={inputHandler}
                                   name={'calory'} type={'range'}
                                   min={200} max={1500} step={50}/>
                        </fieldset>
                        <fieldset className={s.wrapper__video__settings__hr}>
                            <label htmlFor={'hr'}>Target HR ({baseWorkoutData.hr})</label>
                            <Input required={true} value={baseWorkoutData.hr} onChange={inputHandler} name={'hr'}
                                   type={'range'} min={60}
                                   max={180} step={5}/>
                        </fieldset>
                    </div>
                </div>

                {video && (
                    <div className={s.wrapper__parts}>
                        {parts.map(({nodes, name, partId, targetHR}) => {

                            const _fieldHandler = ({target: {name, value}}) => {
                                const [nodeId, type] = name.split('_')
                                partsChangeHandler(type, value, partId, nodeId)
                            }

                            return (
                                <div className={s.wrapper__parts__tile}>
                                    <div className={s.wrapper__parts__tile__header}>
                                        <Input id={partId} name={'part_name'} value={name} onChange={partNameHandler}/>
                                        <Input id={partId} name={'targetHR'} value={targetHR}
                                               onChange={targetHRHandler}/>
                                        {parts.length >= 2 &&
                                            <Button type={'button'} onClick={removePart.bind(null, partId)}
                                                    title={'Remove part'}/>}
                                        <Button type={'button'} onClick={addPart} title={'Add new Part'}/>
                                    </div>
                                    <table className={s.wrapper__parts__tile__part} key={partId}>
                                        <thead>
                                        <tr>
                                            <th>Difficulty</th>
                                            <th>Time Start</th>
                                            <th>Time End</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {nodes.map(({timeStart, timeEnd, difficulty, nodeId}) => {
                                            return (
                                                <tr key={nodeId} className={s.wrapper__parts__tile__part__nodes}>
                                                    <td>{difficulty}</td>
                                                    <td width={'30%'}><Input onChange={_fieldHandler}
                                                                             name={`${nodeId}_timeStart`}
                                                                             value={timeStart}/></td>
                                                    <td width={'30%'}><Input onChange={_fieldHandler}
                                                                             name={`${nodeId}_timeEnd`}
                                                                             value={timeEnd}/></td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                            )
                        })}
                    </div>
                )}
                <Button className={s.wrapper__submit} disabled={!video} type={'submit'}>Create workout</Button>
            </form>
        </>
    )
}

const CreatingWorkout = () => {
    return (
        <>
            <div className={s.dialog__content__header}>
                <p data-current-step={true}>1</p>
                <span/>
                <p>2</p>
                <span/>
                <p>3</p>
                <span/>
                <p>4</p>
            </div>
            <div className={s.dialog__content__progress}>
                <p className={s.dialog__content__progress__first}>Creating workout data</p>
            </div>
        </>
    )
}

const UploadingWorkout = ({progress}) => {
    return (
        <>
            <div className={s.dialog__content__header}>
                <p>1</p>
                <span/>
                <p data-current-step={true}>2</p>
                <span/>
                <p>3</p>
                <span/>
                <p>4</p>
            </div>
            <div className={s.dialog__content__progress}>
                <p>Uploading video</p>
                <div className={s.dialog__content__progress__bar}>
                    <span style={{width: progress + '%'}}/>
                    <p>{progress}%</p>
                </div>
            </div>
        </>
    )
}

const PreparingParts = () => {
    return (
        <>
            <div className={s.dialog__content__header}>
                <p>1</p>
                <span/>
                <p>2</p>
                <span/>
                <p data-current-step={true}>3</p>
                <span/>
                <p>4</p>
            </div>
            <div className={s.dialog__content__progress}>
                <p className={s.dialog__content__progress__first}>Preparing parts</p>
            </div>
        </>
    )
}

const UploadingParts = ({innerWorkoutId, preparedParts, onSuccess, onError, videoId}) => {
    const navigate = useNavigate()

    const publishClickHandler = () => {
        new WorkoutsAPI().updateWorkoutVideo({
            url: `https://vimeo.com/${videoId}`,
            parts: preparedParts,
        }, innerWorkoutId).then(res => {
            onSuccess && onSuccess()
            window.open(`${window.location.origin}/dashboard/workouts/${innerWorkoutId}`, '_blank');
            navigate(`/dashboard/workouts`, {target: '_blank'})
        }).catch((e) => {
            onError && onError(e)
        })
    }

    return (
        <>
            <div className={s.dialog__content__header}>
                <p>1</p>
                <span/>
                <p>2</p>
                <span/>
                <p>3</p>
                <span/>
                <p data-current-step={true}>4</p>
            </div>
            <div className={s.dialog__content__progress}>
                <Button className={s.dialog__content__progress__publish} onClick={publishClickHandler}>Update parts and
                    publish</Button>
            </div>
        </>
    )
}

