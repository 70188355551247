import {Button} from "../../../components/button/Button";

export const EquipmentsListTableRow = ({_id, name, e, d}) => {
    return (
        <tr key={_id}>
            <td>{name}</td>
            <td>
                <Button onClick={e.bind(null, name)} title={'Edit'}/>
            </td>
            <td>
                <Button onClick={d.bind(null, name)} title={'Delete'}/>
            </td>
        </tr>
    )
}