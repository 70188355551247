import {WorkoutsListTableRow} from "./WorkoutsListTableRow";

export const WorkoutsListTable = ({workoutsList, d}) => {
    return workoutsList?.length ? (
        <table>
            <thead>
            <tr>
                <td>Title</td>
                <td>Body Part</td>
                <td>Style</td>
                <td>Instructor</td>
            </tr>
            </thead>
            <tbody>
            {workoutsList.map(workout => <WorkoutsListTableRow {...workout}/>)}
            </tbody>
        </table>
    ) : <h2 id={'nothing-found'}>Nothing found....</h2>
}
