import {saveEquipmentsList, setIsEquipmentsLoadingStatus} from "../actions/equipments-actions";
import EquipmentsAPI from "../../api/EquipmentsAPI";

export const getAllEquipmentsList = (offset, limit, text) => {
    return dispatch => {
        dispatch(setIsEquipmentsLoadingStatus(true))

        new EquipmentsAPI().getAllEquipments(offset, limit, text).then(({data}) => {
            console.log(data)
            dispatch(saveEquipmentsList(data?.item, data?.count))
        }).catch(error => console.log(error)).finally(() => {
            dispatch(setIsEquipmentsLoadingStatus(false))
        })
    }
}

export const removeEquipmentFromList = (currentList, totalCount, removeId) => {
    let newList = currentList;

    let index = newList.findIndex(e => e._id === removeId)

    if (index !== -1) {
        newList.splice(index, 1)

        return dispatch => {
            dispatch(saveEquipmentsList(newList, totalCount - 1))
        }
    }
}

export const updateEquipmentInList = (currentList, newData, updateId, totalCount) => {
    let newList = currentList;

    let index = newList.findIndex(e => e._id === updateId)

    if (index !== -1) {
        newList.splice(index, 1, {...newData})

        return dispatch => {
            dispatch(saveEquipmentsList(newList, totalCount))
        }
    }
}

export const addEquipmentToList = (currentList, newData, totalCount) => {
    return dispatch => {
        dispatch(saveEquipmentsList([...currentList, newData], totalCount + 1))
    }
}
