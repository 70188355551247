import {useNavigate} from "react-router-dom";

export const UsersListTableRow = ({_id, name, email, role, status}) => {
    const navigate = useNavigate()

    return (
        <tr onClick={navigate.bind(null, `/dashboard/users/${_id}`, {replace: true})} key={_id}>
            <td>{name}</td>
            <td>{email}</td>
            <td>{role}</td>
            <td>{status}</td>
        </tr>
    )
}