import {EntitiesPageHeader} from "../../../components/search-bar/EntitiesPageHeader";
import {useDispatch, useSelector} from "react-redux";
import {ChallengesListTable} from "./ChallengesListTable";
import {challengesSelector} from "../../../store/selectors/challenges-selector";
import {PaginationPanel} from "../../../components/pagination-panel/PaginationPanel";
import {getChallengesList} from "../../../store/operations/challenges-operations";

import {useNavigate} from "react-router-dom";
import {withAllowedQuery} from "../../../hocs/withAllowedQuery";
import {useEffect, useState} from "react";

export const ChallengesListPage = withAllowedQuery(['text'])(({queryParams, setQueryParams}) => {
    const [searchText, setSearchText] = useState(queryParams.text || '')
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const {challengesList, totalCount, isLoading} = useSelector(challengesSelector)

    const onPageChange = page => {
        dispatch(getChallengesList(page * 20, 20, searchText))
    }

    useEffect(() => {
        dispatch(getChallengesList(0, 20, searchText))
    }, [queryParams])

    const createButtonHandler = () => {
        navigate('/dashboard/challenges/create', {replace: true})
    }

    const searchButtonHandler = (event) => {
        event.preventDefault()
        setQueryParams({
            text: searchText
        })
    }

    return (
        <>
            <EntitiesPageHeader input={{
                disabled: false,
                value: searchText,
                onChange: setSearchText
            }} buttons={[{title: 'Search', clickHandler: searchButtonHandler, type: 'submit'}, {title: 'Create', clickHandler: createButtonHandler}]}/>
            <section>
                <ChallengesListTable challengesList={challengesList}/>
                <PaginationPanel onPageChange={onPageChange} initPage={0} totalPages={Math.ceil(totalCount / 20)}/>
            </section>
        </>
    )
})
