import {
    REMOVE_PROFILE_DATA,
    SAVE_PROFILE_DATA,
    SET_PROFILE_LOADING_STATUS,
    UPDATE_PROFILE_DATA
} from "../action-types/profile-action.types";

const initialState = {
    userData: null,
    isLoading: false,
    error: null
}

export const profileReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case SAVE_PROFILE_DATA:
            return {...state, profileData: payload}
        case REMOVE_PROFILE_DATA:
            return {...state, profileData: null}
        case UPDATE_PROFILE_DATA:
            return {...state, profileData: {...state.profileData, ...payload}}
        case SET_PROFILE_LOADING_STATUS:
            return {...state, isLoading: payload}
        default: return state
    }
}