import {useDispatch} from "react-redux";
import {addAlertContent} from "../store/actions/alerts-actions";

export const useAlert = () => {
    const dispatch = useDispatch()

    const displayErrorMessage = (error) => {
        dispatch(addAlertContent({
            Content: () => <p>{error?.response?.data?.error || error?.response?.data?.message || error?.message}</p>,
            isError: true
        }))
    }

    const displaySuccessMessage = (message) => dispatch(addAlertContent({
        Content: () => <p>{message}</p>,
        isError: false
    }))

    return [displaySuccessMessage, displayErrorMessage]
}
