import {_setInstructorsList, _setIsInstructorsLoading} from "../actions/instructors-actions";
import {InstructorsAPI} from "../../api/InstructorsAPI";

export const getInstructorsList = (offset = 0, limit = 20, onSuccess, onError) => {
    return dispatch => {
        dispatch(_setIsInstructorsLoading(true))

        new InstructorsAPI().getInstructorsList(offset, limit).then(({data: {item, count}}) => {
            dispatch(_setInstructorsList(item, count))
            onSuccess && onSuccess()
        }).catch(error => {
            onError && onError(error)
        }).finally(() => {
            dispatch(_setIsInstructorsLoading(false))
        })
    }
}
