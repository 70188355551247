import {useEffect, useState} from "react";
import UsersAPI from "../../../api/UsersAPI";
import styles from "./user-data.module.scss";
import {Link, useParams} from "react-router-dom";
import {Multiselect, Select} from "../../../components/select-menu";
import {Button} from "../../../components/button/Button";
import {useDispatch} from "react-redux";
import {useAlert} from "../../../hooks/useAlert";
import {Input} from "../../../components/input/Input";
import {ResetButton} from "../../../components/reset-button/ResetButton";
import QuizAPI from "../../../api/QuizAPI";
import _ from 'lodash'

export const UserPage = props => {
    const {id} = useParams()
    const [successAlert, errorAlert] = useAlert()

    const [oa, setOa] = useState(null)
    const [{user, userAnswers = null}, setUserData] = useState({})
    const [rolesList, setRolesList] = useState([])
    const [statusesList, setStatusesList] = useState([])
    const [newData, setNewData] = useState(null)
    const [quizQuestions, setQuizQuestions] = useState(null)
    const [newAnswers, setNewAnswers] = useState(null)
    const [isQuizChanged, setIsQuizChanged] = useState(false)

    useEffect(() => {
        new UsersAPI().getUserPermissions().then(({data}) => {
            setRolesList(data.roles.map((r, index) => ({_id: index, title: r})))
            setStatusesList(data.statusArr.map((s, index) => ({_id: index, title: s})))
        })
    }, [])

    useEffect(() => {
        new QuizAPI().getQuizList().then(({data}) => {
            setQuizQuestions(data.items)
        }).catch(error => errorAlert(error))
    }, [])

    useEffect(() => {
        new UsersAPI().getUserById(id).then(res => {
            setUserData(res.data)
        }).catch(error => console.log(error))
    }, [id])

    useEffect(() => {
        new UsersAPI().getUserById(id).then(res => {
            setOa(res.data.userAnswers)
        }).catch(error => console.log(error))
    }, [id])

    useEffect(() => {
        setNewData({
            name: user?.name,
            gender: user?.gender,
            weight: user?.weight,
            height: user?.height,
            status: user?.status,
            abilityLevel: user?.abilityLevel,
            percentHeartRate: user?.percentHeartRate,
            role: user?.role,
            heartRate: user?.heartRate

        })

    }, [user])

    useEffect(() => {
        if (Array.isArray(userAnswers)) {
            setNewAnswers(userAnswers?.map(_answer => {
                return {
                    questionId: _answer.questionId,
                    answer: _answer.answer
                }
            }))
        }
    }, [userAnswers])

    const valueReplacer = (value) => {
        if ((typeof value === 'number') && (value === 0)) return '0'
        else if (typeof value === 'string') return value
        else if (typeof value === 'undefined') return 'Unknown'
        else return value
    }

    const resetHandler = (fieldName) => {
        const fields = fieldName.split('__')

        if (fields.length > 1) {
            newDataHandler(fieldName, user[fields[0]][fields[1]])
        } else newDataHandler(fieldName, user[fieldName])
    }

    const createDate = (dateStr) => {
        const date = new Date(dateStr)
        return `${date.getUTCFullYear()}/${date.getUTCMonth() + 1}/${date.getDate()}`
    }

    const newDataHandler = (name, value) => {

        const fields = name.split('__')

        let _value = value

        if (name === 'height' || name === 'abilityLevel' || name === 'weight') {
            _value = value.replace('-', '')
        }


        if (fields.length > 1) {
            setNewData({
                ...newData,
                [fields[0]]: {
                    ...newData[fields[0]],
                    [fields[1]]: _value
                }
            })
        } else setNewData({...newData, [name]: _value})
    }

    const updateUserDataHandler = () => {
        new UsersAPI().updateUserData(user._id, newData).then(response => {
            window.location.reload()
        }).catch(errorAlert)
    }

    const separateQuestionList = () => {
        return quizQuestions?.filter(_quiz => _quiz.save)
    }

    const separateAnswers = (questionId) => {
        return newAnswers?.filter(answer => answer.questionId === questionId)[0]?.answer || []
    }

    const submitHandler = () => {
        new QuizAPI().updateQuizAnswers(user._id, newAnswers.map((el, i) => {
            if (i === 0) {
                return {
                    ...el,
                    answer: el.answer.map(_a => ({id: _a.id, text: _a.text}))
                }
            } else return el
        })).then(res => {
            window.location.reload()
        }).catch(error => errorAlert(error))
    }

    const selectAnswerHandler = (name, value) => {
        const [type, questionId] = name.split('__')
        let match, aIndex, _value;
        let valuesArrays;

        if (typeof value === 'string' || typeof value === "number") {
            let _a = `${value}`.match(/^([0-9+])\.? (.+)/)
            if (Array.isArray(_a)) {
                [match, aIndex, _value] = _a
            }
        } else valuesArrays = value?.map(v => {
            const [match, a, _v] = v.match(/^([0-9+])\.? (.+)/)

            return {aIndex: a, _value: _v}
        })

        let _newAnswers = newAnswers;

        let _na = _newAnswers.findIndex(_na => {
            return +_na.questionId === +questionId
        })

        if (_newAnswers[_na]) {
            if (valuesArrays?.length) {
                _newAnswers[_na].answer = valuesArrays.map(_v => {
                    return {
                        id: +_v.aIndex,
                        text: _v._value
                    }
                })
            } else if (aIndex && _value) {
                _newAnswers[_na].answer = [{id: +aIndex, text: _value}]
            } else {
                _newAnswers[_na].answer = [{text: value}]
            }
        }

        setNewAnswers([..._newAnswers])
    }

    const inputAnswerHandler = ({target: {value, name, type}}) => {
        if (value < 0) {
            return;
        }

        console.log(type)

        const [t, q, answerId] = name.split('__')

        let _newAnswers = newAnswers;

        let _aIndex = _newAnswers[0].answer.findIndex(a => +a.id === +answerId)

        _newAnswers[0].answer[_aIndex].text = value

        setNewAnswers([..._newAnswers])
    }

    const getFields = (answer) => {
        if (newAnswers) {
            switch (answer.type) {
                case 'MULTI_INPUT':
                    return (
                        <div className={styles.user__quiz__block__multi_input}>
                            {answer.answers.map(_answer => {
                                return (
                                    <div className={styles.user__quiz__block__multi_input__wrapper}>
                                        <span>{_answer?.text && `${_answer?.text}, `}{_answer?.unit}</span>
                                        <Input onChange={inputAnswerHandler}
                                               name={answer?.type + '__' + answer?.id + '__' + _answer?.id}
                                               value={newAnswers[0]?.answer[_answer.id]?.text} type={'number'}/>
                                    </div>
                                )
                            })}
                        </div>
                    )
                case 'DROPDOWN':
                    return (
                        <div className={styles.user__quiz__block__dropdown}>
                            <Select onSelect={selectAnswerHandler} name={answer?.type + '__' + answer?.id}
                                    value={{title: `${separateAnswers(answer.id)[0]?.text}`}}
                                    selectClassName={styles.user__quiz__block__dropdown__menu}
                                    options={answer.enum.map(e => ({title: `${e}`}))}/>
                        </div>
                    )
                case 'SELECT':
                    return (
                        <div className={styles.user__quiz__block__dropdown}>
                            <Select onSelect={selectAnswerHandler} name={answer.type + '__' + answer.id} value={{
                                title: `${separateAnswers(answer.id)[0]?.id}. ${separateAnswers(answer.id)[0]?.text}`,
                            }} selectClassName={styles.user__quiz__block__dropdown__menu}
                                    options={answer.answers.map(e => ({title: `${e.id}. ${e.text}`,}))}/>
                        </div>
                    )
                case 'MULTI_SELECT':
                    return (
                        <div className={styles.user__quiz__block__dropdown}>
                            <Multiselect onSelect={selectAnswerHandler} name={answer.type + '__' + answer.id}
                                         values={separateAnswers(answer.id).map(a => ({
                                             title: `${a.id}. ${a.text}`,
                                             _id: `${a.id}. ${a.text}`
                                         }))} selectClassName={styles.user__quiz__block__dropdown__menu}
                                         options={answer.answers.map(e => ({
                                             title: `${e.id}. ${e.text}`,
                                             _id: `${e.id}. ${e.text}`
                                         }))}/>
                        </div>
                    )
                default:
                    return <p>s</p>
            }
        }

    }

    const isChanged = () => {
        console.log(oa?.map(e => e.answer), newAnswers?.map(e => e.answer))
        return _.isEqual(oa?.map(e => e.answer), newAnswers?.map(e => e.answer))
    }

    return (
        <>
            <section className={styles.user__main_data}>

                {user?.avatarUrl ?
                    <img className={styles.user__main_data_avatar} src={user?.avatarUrl} alt={'avatar'}/> :
                    <span className={styles.user__main_data_avatar}>{user?.name && user?.name[0]}</span>}

                <div className={styles.user__main_data__personal}>
                    <p>
                        <span>Name: </span>
                        <Input onChange={({target: {name, value}}) => newDataHandler(name, value)}
                               value={valueReplacer(newData?.name)} name={'name'}/>
                        <ResetButton className={styles.user__main_data__personal__reset}
                                     condition={newData?.name !== user?.name} alt={'name_reset'}
                                     onClick={resetHandler.bind(null, 'name')}/>
                    </p>

                    <p>
                        <span>Gender: </span>
                        <Input onChange={({target: {name, value}}) => newDataHandler(name, value)}
                               value={valueReplacer(newData?.gender)} name={'gender'}/>
                        <ResetButton className={styles.user__main_data__personal__reset}
                                     condition={newData?.gender !== user?.gender} alt={'gender_reset'}
                                     onClick={resetHandler.bind(null, 'gender')}/>
                    </p>

                    <p><span>DOB (YY/MM/DD):</span> {createDate(user?.birthday) || 'Unknown'}</p>

                    <p><span>Email: </span> {user?.email}</p>

                    <p>
                        <span>Ability Level:</span>
                        <Input onChange={({target: {name, value}}) => newDataHandler(name, value)}
                               value={valueReplacer(newData?.abilityLevel)} name={'abilityLevel'}/>
                        <ResetButton className={styles.user__main_data__personal__reset}
                                     condition={newData?.abilityLevel !== user?.abilityLevel} alt={'abilityLevel_reset'}
                                     onClick={resetHandler.bind(null, 'abilityLevel')}/>
                    </p>

                    <p>
                        <span>Weight:</span>
                        <Input onChange={({target: {name, value}}) => newDataHandler(name, value)}
                               value={valueReplacer(newData?.weight)} name={'weight'}/>
                        <ResetButton className={styles.user__main_data__personal__reset}
                                     condition={newData?.weight !== user?.weight} alt={'weight_reset'}
                                     onClick={resetHandler.bind(null, 'weight')}/>
                    </p>

                    <p>
                        <span>Height: </span>
                        <Input onChange={({target: {name, value}}) => newDataHandler(name, value)}
                               value={valueReplacer(newData?.height)} name={'height'}/>
                        <ResetButton className={styles.user__main_data__personal__reset}
                                     condition={newData?.height !== user?.height} alt={'height_reset'}
                                     onClick={resetHandler.bind(null, 'height')}/>
                    </p>

                    <p>
                        <span>Percent HR Above: </span>
                        <Input onChange={({target: {name, value}}) => newDataHandler(name, value)}
                               value={valueReplacer(newData?.heartRate?.above)} name={'heartRate__above'}/>
                        <ResetButton className={styles.user__main_data__personal__reset}
                                     condition={newData?.heartRate?.above !== user?.heartRate?.above}
                                     alt={'heartRate__above_reset'}
                                     onClick={resetHandler.bind(null, 'heartRate__above')}/>
                    </p>

                    <p>
                        <span>Percent HR Below: </span>
                        <Input onChange={({target: {name, value}}) => newDataHandler(name, value)}
                               value={valueReplacer(newData?.heartRate?.below)} name={'heartRate__below'}/>
                        <ResetButton className={styles.user__main_data__personal__reset}
                                     condition={newData?.heartRate?.below !== user?.heartRate?.below}
                                     alt={'heartRate__below_reset'}
                                     onClick={resetHandler.bind(null, 'heartRate__below')}/>
                    </p>

                    <p>
                        <span>Current Plan/Challenge: </span>
                        <Link target={'_blank'} to={`/dashboard/challenges/${user?.planId}`}>Details</Link>
                    </p>

                    <p><span>Current Plan/Challenge day: </span> {user?.currentDay}</p>
                </div>

            </section>

            <section className={styles.user__settings}>

                <fieldset>
                    <legend>User Role</legend>
                    <Select name={'role'} options={rolesList} selectClassName={styles.user__settings__select}
                            placeholder={user?.role || 'Role not set'} onSelect={newDataHandler}/>
                </fieldset>

                <fieldset>
                    <legend>User Status</legend>
                    <Select name={'status'} options={statusesList} selectClassName={styles.user__settings__select}
                            placeholder={user?.status || 'Status not set'} onSelect={newDataHandler}/>
                </fieldset>

                <Button onClick={updateUserDataHandler} title={'Submit profile settings'}/>

            </section>

            {Array.isArray(userAnswers) && (
                <section className={styles.user__quiz}>
                    {separateQuestionList()?.map(el => {
                        return (
                            <>
                                <h2>{el.title || el.description}</h2>
                                <div className={styles.user__quiz__block}>
                                    {getFields(el)}
                                </div>
                            </>
                        )
                    })}
                    <Button disabled={isChanged()} onClick={submitHandler}>Save Quiz Answers</Button>
                </section>
            )}
        </>
    )
}
