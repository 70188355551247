import {BaseAPI} from "./BaseAPI";

class QuizAPI extends BaseAPI {
    constructor() {
        super('')
    }

    getUsersQuizAnswers = async (id) => {
        return await this.service.get(`quiz-answer/user/${id}`)
    }

    getQuizList = async () => {
        return await this.service.get('quiz')
    }

    updateQuizAnswers = async (id, answers) => {
        return await this.service.post(`quiz-answer/${id}`, {answers})
    }
}


export default QuizAPI
