import resetIcon from '../../assets/image/reset.png'

const imgStyle = {
    height: '100%',
    width: '100%',
    cursor: 'pointer'
}

export const ResetButton = ({condition, onClick, className, alt}) => {
    if (!condition) return null

    return (
        <div className={className}>
            <img style={imgStyle} src={resetIcon} alt={alt} onClick={onClick}/>
        </div>
    )
}