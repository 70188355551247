import {combineReducers} from "redux";
import {profileReducer}  from "../reducers/profile-reducer";
import {usersReducer} from "../reducers/users-reducer";
import {workoutsReducer} from "../reducers/workouts-reducer";
import {equipmentsReducer} from "../reducers/equipments-reducer";
import {challengesReducer} from "../reducers/challenges-reducer";
import {alertsReducer} from "../reducers/alerts-reducer";
import {instructorsReducer} from "../reducers/instructors-reducer";

export const reducers = combineReducers({
    profileState: profileReducer,
    usersState: usersReducer,
    workoutsState: workoutsReducer,
    equipmentsState: equipmentsReducer,
    challengesState: challengesReducer,
    alertsState: alertsReducer,
    instructorsState: instructorsReducer,
});
