import styles from './delete-window.module.scss'
import EquipmentsAPI from "../../../api/EquipmentsAPI";
import {Button} from "../../../components/button/Button";
import {useDispatch, useSelector} from "react-redux";
import {equipmentsSelector} from "../../../store/selectors/equipments-selector";
import {removeEquipmentFromList} from "../../../store/operations/equipments-operations";
import {useAlert} from "../../../hooks/useAlert";

export const DeleteWindow = ({selectedEntity, closeModal}) => {
    const dispatch = useDispatch()

    const {equipmentsList, totalCount} = useSelector(equipmentsSelector)

    const [successAlert, errorAlert] = useAlert()

    const submitHandler = () => {
        new EquipmentsAPI().deleteEquipment(selectedEntity).then((res) => {
            dispatch(removeEquipmentFromList(equipmentsList, totalCount, res.data._id))
            closeModal && closeModal()
            successAlert('Equipment has been deleted')
        }).catch(errorAlert)
    }

    return (
        <>
            <p className={styles.delete_header}>Confirm delete: <span>{selectedEntity}</span></p>
            <section className={styles.delete__buttons}>
                <Button title={'Confirm'} onClick={submitHandler}/>
                <Button title={'Cancel'} isGrey={true} onClick={closeModal}/>
            </section>
        </>
    )
}