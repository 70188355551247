import {BaseAPI} from "./BaseAPI";

class UsersAPI extends BaseAPI{
    constructor() {
        super('user')
    }

    getAllUsers = async (offset, limit, text) => {
        return await this.service.get('/', {
            params: {
                offset,
                limit,
                text
            }
        },)
    }

    getUserById = async (id) => {
        return await this.service.get(`/${id}`)
    }

    getUserPermissions = async () => {
        return await this.service.get('/permissions')
    }

    updateUserData = async (id, newData) => {
        return await this.service.put(`/${id}`, newData)
    }
}

export default UsersAPI
