import {useEffect, useState} from "react";
import SettingsAPI from "../../api/SettingsAPI";
import styles from './default-settings.module.scss'
import {Button} from "../../components/button/Button";
import {Input} from "../../components/input/Input";
import {useAlert} from "../../hooks/useAlert";
import _ from 'lodash'

export const DefaultSettings = props => {
    const [currentDefaultSettings, setCurrentDefaultSettings] = useState(null)
    const [newDefaultSettings, setNewDefaultSettings] = useState(null)
    const [successAlert, errorAlert] = useAlert()

    useEffect(() => {
        requestDefaultSettings()
    }, [])

    const requestDefaultSettings = () => {
        new SettingsAPI().getDefaultSettings().then(({data}) => {
            setCurrentDefaultSettings(data)
            setNewDefaultSettings(data)
        }).catch(errorAlert)
    }

    const fieldsHandler = ({target}) => {
        setNewDefaultSettings({
            ...newDefaultSettings,
            [target.name]: +target.value
        })
    }

    const updateHandler = () => {
        new SettingsAPI().setDefaultSettings(newDefaultSettings).then(res => {
            requestDefaultSettings()
            successAlert('New settings has been saved')
        }).catch(errorAlert)

    }

    return (
        <section onChange={fieldsHandler}>
            {/*<section className={styles.option}>
                <p>Minutes of workouts that has to be watched to be considered viewed</p>
                <Input min={7} disabled={true} name={'passedVideoTime'} value={newDefaultSettings?.passedVideoTime}
                       type={'number'}/>
                <ResetButton className={styles.reset_button} alt={'passedVideoTime_reset'}
                             onClick={resetHandler.bind(null, 'passedVideoTime')}
                             condition={newDefaultSettings?.passedVideoTime !== currentDefaultSettings?.passedVideoTime}
                />
            </section>*/}
            <table>
                <thead>
                <tr className={styles.option}>
                    <th>Option</th>
                    <th>Value</th>
                </tr>
                </thead>
                <tbody>
                <tr className={styles.option}>
                    <td>Number of times they should repeat a workout in a week</td>
                    <td>
                        <Input max={7} step={1} name={'countRepeatWorkoutForWeek'}
                               value={newDefaultSettings?.countRepeatWorkoutForWeek}
                               type={'number'}/>
                    </td>
                </tr>
                <tr className={styles.option}>
                    <td>
                        Percent of above or below for HR
                    </td>
                    <td>
                        <Input name={'percentOfAboveORBelowForHR'}
                               value={newDefaultSettings?.percentOfAboveORBelowForHR}
                               type={'number'}/>
                    </td>
                </tr>
                <tr className={styles.option}>
                    <td>
                        Percent of section above for HR
                    </td>
                    <td>
                        <Input name={'percentOfSectionAboveForHR'}
                               value={newDefaultSettings?.percentOfSectionAboveForHR}
                               type={'number'}/>
                    </td>
                </tr>
                <tr className={styles.option}>
                    <td>
                        Percent of section below for HR
                    </td>
                    <td>
                        <Input name={'percentOfSectionBelowForHR'}
                               value={newDefaultSettings?.percentOfSectionBelowForHR}
                               type={'number'}/>
                    </td>
                </tr>
                <tr className={styles.option}>
                    <td>Percent of completed nodes to mark workout as completed</td>
                    <td>
                        <Input name={'percentPartForCompleteDay'} value={newDefaultSettings?.percentPartForCompleteDay}
                               type={'number'}
                               min={0} max={100}
                        />
                    </td>
                </tr>
                </tbody>
            </table>

            {!_.isEqual(newDefaultSettings, currentDefaultSettings) && (
                <Button onClick={updateHandler} title={'Update settings'}/>
            )}
        </section>
    )

}
