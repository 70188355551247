import {ADD_ALERT_CONTENT, REMOVE_ALERT_CONTENT, SET_IS_ALERT_VISIBLE} from "../action-types/alerts-action.types";

const initState = {
    isVisible: false,
    alerts: []
}

export const alertsReducer = (state = initState, {payload, type}) => {
    switch (type) {
        case ADD_ALERT_CONTENT:
            return {...state, alerts: [...state.alerts, payload]}
        case REMOVE_ALERT_CONTENT:
            return {...state, alerts: [...state.alerts.filter(({alertId}) => alertId !== payload)]}
        case SET_IS_ALERT_VISIBLE:
            return {...state, isVisible: payload}
        default: return state
    }
}