import {addUsersToList, clearUsersList, setUsersLoadingStatus} from "../actions/users-actions";
import UsersAPI from "../../api/UsersAPI";

export const getUsersList = (offset = 0, limit = 20, text) => {
    return dispatch => {
        dispatch(setUsersLoadingStatus(true))

        dispatch(clearUsersList())

        new UsersAPI().getAllUsers(offset, limit, text).then(({data}) => {
            dispatch(addUsersToList(data?.items, data?.count))
        }).catch(error => {
            console.log(error)
        }).finally(() => dispatch(setUsersLoadingStatus(false)))
    }
}
