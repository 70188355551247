import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {addAlertContent} from "../../../store/actions/alerts-actions";
import WorkoutsAPI from "../../../api/WorkoutsAPI";

import styles from './workout-page.module.scss'
import {Button} from "../../../components/button/Button";
import {useAlert} from "../../../hooks/useAlert";

export const WorkoutPage = props => {
    const {id} = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const ref = useRef(null)

    const [currentPosition, setCurrentPosition] = useState(0)
    const [workoutData, setWorkoutData] = useState(null)

    const [successAlert, errorAlert] = useAlert()

    const deleteHandler = () => {
        new WorkoutsAPI().deleteWorkout(id).then(res => {
            successAlert('Workout has been deleted')
            navigate('/dashboard/workouts')
        }).catch(errorAlert)
    }

    useEffect(() => {
        if (ref?.current) {
            const player = new Vimeo.Player(ref.current) // eslint-disable-line

            player.setCurrentTime(currentPosition)
        }
    }, [currentPosition])

    useEffect(() => {
        new WorkoutsAPI().getById(id).then(res => {
            setWorkoutData(res.data)
        }).catch(error => {
            errorAlert(error)
            navigate('/dashboard/workouts', {replace: true})
        })
    }, [])

    return (
        <>
            <p className={styles.workout__paragraph}><span>Title: </span>{workoutData?.title}</p>
            <p className={styles.workout__paragraph}><span>Instructor: </span>{workoutData?.instructor}</p>
            <p className={styles.workout__paragraph}><span>Style: </span>{workoutData?.style}</p>
            <p className={styles.workout__paragraph}>
                <span>Body Part: </span>{workoutData?.bodyPart.split('_').join(' ')}</p>
            <p className={styles.workout__paragraph}><span>Calories: </span>{workoutData?.calory}</p>
            <p className={styles.workout__paragraph}><span>Difficulty: </span>{workoutData?.difficulty}</p>
            <p className={styles.workout__paragraph}><span>HR: </span>{workoutData?.hr}</p>
            <p className={styles.workout__paragraph}><span>Equipment: </span>{workoutData?.equipments?.join(' ')}</p>

            {workoutData && (
                <div className={styles.workout__player}>
                    <iframe ref={ref}
                            src={`https://player.vimeo.com/video/${workoutData?.video?.link?.match(/[0-9]+/)}`}
                            frameBorder="0" webkitallowfullscreen mozallowfullscreen
                            allowFullScreen/>
                    {workoutData?.video?.parts?.map(({name, nodes}) => {
                        console.log(name, nodes)
                        return (
                            <table className={styles.workout__player__parts} key={name}>
                                <thead>
                                <tr>
                                    <th>Difficulty</th>
                                    <th>Time Start</th>
                                    <th>Time End</th>
                                    <th>Target HR</th>
                                </tr>
                                </thead>
                                <tbody>
                                {nodes.map(({difficult, timeStart, timeEnd, hr}) => {
                                    return (
                                        <tr className={styles.workout__player__parts__node} key={timeStart + timeEnd} onClick={setCurrentPosition.bind(null, timeStart)}>
                                            <td>{difficult}</td>
                                            <td width={'20%'}>{timeStart}</td>
                                            <td width={'20%'}>{timeEnd}</td>
                                            <td width={'20%'}>{hr}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        )
                    })}
                </div>
            )}
            <Button onClick={deleteHandler} title={'Delete workout'}/>
        </>
    )
}
