import {
    CLEAR_EQUIPMENTS_LIST,
    SAVE_EQUIPMENTS_LIST,
    SET_EQUIPMENTS_IS_LOADING_STATUS
} from "../action-types/equipments-action.types";

const initState = {
    equipmentsList: [],
    totalCount: 0,
    isLoading: false
}

export const equipmentsReducer = (state = initState, {type, payload}) => {
    switch (type) {
        case SAVE_EQUIPMENTS_LIST:
            return {...state, equipmentsList: payload.equipmentsList, totalCount: payload.totalCount}
        case CLEAR_EQUIPMENTS_LIST:
            return {...state, equipmentsList: [], totalCount: 0}
        case SET_EQUIPMENTS_IS_LOADING_STATUS:
            return {...state, isLoading: payload}
        default: return state
    }
}