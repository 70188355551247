import {useEffect, useState} from "react";

import styles from './edit-window.module.scss'
import EquipmentsAPI from "../../../api/EquipmentsAPI";
import {Input} from "../../../components/input/Input";
import {Button} from "../../../components/button/Button";
import {useDispatch, useSelector} from "react-redux";
import {equipmentsSelector} from "../../../store/selectors/equipments-selector";
import {updateEquipmentInList} from "../../../store/operations/equipments-operations";
import {useAlert} from "../../../hooks/useAlert";

export const EditWindow = ({selectedEntity, closeModal}) => {
    const [newName, setNewName] = useState("")

    const {equipmentsList, totalCount} = useSelector(equipmentsSelector)

    const dispatch = useDispatch()

    const [successAlert, errorAlert] = useAlert()

    const newNameHandler = ({target}) => {
        setNewName(target.value)
    }

    const submitHandler = () => {
        if (newName.match(/^ +$/) || newName === '') {
            errorAlert({message: 'Equipment name can not be empty'})
        } else {
            new EquipmentsAPI().updateEquipment(selectedEntity, newName).then((res) => {
                dispatch(updateEquipmentInList(equipmentsList, res.data, res.data._id, totalCount))
                successAlert('Equipment was updated!')
                closeModal && closeModal()
            }).catch(errorAlert)
        }
    }

    useEffect(() => {
        setNewName('')
    }, [selectedEntity])

    return (
        <>
            <p className={styles.edit_old_name}>Old name: <span>{selectedEntity}</span></p>
            <Input placeholder={'New name'} className={styles.edit_input} value={newName} onChange={newNameHandler}/>
            <section className={styles.edit__buttons}>
                <Button onClick={submitHandler} title={'Apply'}/>
                <Button onClick={closeModal} isGrey={true} title={'Cancel'}/>
            </section>
        </>
    )
}