import styles from './pagination-panel.module.scss'
import {useEffect, useState} from "react";

export const PaginationPanel = ({totalPages, onPageChange, initPage = 0, className}) => {
    const [currentPage, setCurrentPage] = useState(initPage)

    const [pagesArray, setPagesArray] = useState([])

    useEffect(() => {
        setPagesArray(new Array(totalPages).fill('').map((el, i) => {
            return {
                pageNumber: i + 1,
                onClick: () => setCurrentPage(i)
            }
        }))
    }, [totalPages])

    useEffect(() => {
        onPageChange(currentPage)
    }, [currentPage])

    return (
        <section className={`${className ? className : ''} ${styles.pagination}`}>
            {pagesArray.slice((currentPage - 5) <= 0 ? 0 : currentPage - 5, currentPage + 5 >= totalPages ? totalPages : currentPage + 5).map((el, i) => {
                return <span className={`${currentPage + 1 === el.pageNumber && styles.pagination_active}`} key={el.pageNumber} onClick={el.onClick}>{el.pageNumber}</span>
            })}
        </section>
    )
}