import styles from "./search-bar.module.scss"
import {Input} from "../input/Input";
import {Button} from "../button/Button";

export const EntitiesPageHeader = ({placeholder, buttons = [], input = {value: '', onChange: null, disabled: true}}) => {

    const _inputValueHandler = ({target: {value}}) => {
        input.onChange && input.onChange(value)
    }

    return (
        <form className={styles.search}>
            <Input className={styles.search__input} value={input.value} disabled={input.disabled} onChange={_inputValueHandler} placeholder={placeholder}/>
            {buttons.map(({clickHandler, title, disabled, type = 'button'}) => {
                return (
                    <Button type={type} title={title} disabled={disabled} onClick={clickHandler} key={title + 'button'}/>
                )
            })}

        </form>
    )
}
