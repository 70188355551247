import axios from "axios";
import {apiBaseUrls} from "../assets/constants";
import {onRequestFulfilled, onRequestReject} from "./utils/request.interceptors";
import {onResponseFulfilled, onResponseReject} from "./utils/response.interceptors";

export class BaseAPI {
    constructor(url, useInterceptor = true) {
        this.service = axios.create({
            baseURL: apiBaseUrls[process.env.REACT_APP_ENV] + url,
            headers: {
                "deviceId": '123',
                "platform": 'WEB'
            }
        })

        if (useInterceptor) {
            this.service.interceptors.request.use(onRequestFulfilled, onRequestReject);

            this.service.interceptors.response.use(onResponseFulfilled, onResponseReject);
        }
    }
}
