import {useState} from "react";
import {InstructorsAPI} from "../../../api/InstructorsAPI";
import {useAlert} from "../../../hooks/useAlert";
import {Input} from "../../../components/input/Input";
import {Button} from "../../../components/button/Button";

import styles from '../instructors.module.scss'

export const EditInstructorModalWindow = ({_id, name}) => {
    const [_name, _setName] = useState(name || '')

    const [successAlert, errorAlert] = useAlert()

    const submitHandler = (event) => {
        event.preventDefault()

        if (_id) {
            new InstructorsAPI().updateInstructor(_id, _name).then(res => {
                window.location.reload()
            }).catch((error) => {
                errorAlert(error)
            })
        } else {
            new InstructorsAPI().createNewInstructor(_name).then(res => {
                window.location.reload()
            }).catch((error) => {
                errorAlert(error)
            })
        }
    }

    const deleteHandler = () => {
        new InstructorsAPI().deleteInstructor(_id).then(res => {
            window.location.reload()
        }).catch((error) => {
            errorAlert(error)
        })
    }

    const nameHandler = ({target: {value}}) => {
        _setName(value)
    }

    return (
        <>
            <p>Create new Instructor:</p>
            <form className={styles.modal__form} onSubmit={submitHandler}>
                <Input placeholder={'Instructor Name'} onChange={nameHandler} name={'name'} value={_name}/>
                <Button className={styles.modal__form__button} disabled={_name === ''}
                        type={'submit'}>{_id ? 'Update' : 'Create'}</Button>
                {_id && <Button className={styles.modal__form__button} onClick={deleteHandler} type={'button'}>Delete
                    Instructor</Button>}
            </form>
        </>
    )
}
