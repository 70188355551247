import styles from "./equipments.module.scss";
import {EquipmentsListTableRow} from "./EquipmentsListTableRow";

export const EquipmentsListTable = ({equipmentsList, e, d}) => {
    return equipmentsList?.length ? (
        <table className={styles.equipment__table}>
            <thead>
            <tr>
                <td>Name</td>
                <td colSpan={2}>Actions</td>
            </tr>
            </thead>
            <tbody>
            {equipmentsList?.map(equipment => <EquipmentsListTableRow {...equipment} e={e} d={d}/>)}
            </tbody>
        </table>
    ) : <h2 id={'nothing-found'}>Nothing found....</h2>
}
