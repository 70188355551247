import {useNavigate} from "react-router-dom";

export const ChallengesListTableRow = ({_id, title, description}) => {
    const navigate = useNavigate()

    return (
        <tr onClick={navigate.bind(null, `/dashboard/challenges/${_id}`)} key={_id}>
            <td>{title}</td>
            <td>{description}</td>
        </tr>
    )
}